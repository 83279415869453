import React from "react";

const HealthSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 64 64"
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg" id="Outline">
          <path
            d="M61.517,38.645l-.014-.024A4.738,4.738,0,0,0,55.393,37l-6.408,3.277a5.516,5.516,0,0,0-7-1.713L33.915,42.7a2.933,2.933,0,0,1-2.719-.017l-5.5-2.92a12.769,12.769,0,0,0-13.342.808l-.621-2.962a1,1,0,0,0-1.189-.772L2.789,38.51a1,1,0,0,0-.768,1.182L6.536,61.205a1,1,0,0,0,.978.8,1.062,1.062,0,0,0,.21-.022l7.759-1.67a1,1,0,0,0,.768-1.183l-.4-1.925,2.651-.571a6.858,6.858,0,0,1,2.351-.09l12.468,1.367A10.7,10.7,0,0,0,40.7,56.179l19.973-13.4A3.017,3.017,0,0,0,61.517,38.645ZM8.286,59.811l-4.1-19.555,5.8-1.25,4.1,19.556ZM59.56,41.121l-19.973,13.4a8.723,8.723,0,0,1-6.029,1.4L21.09,54.554a8.714,8.714,0,0,0-3.012.12l-2.642.568L12.8,42.7l.683-.476a10.775,10.775,0,0,1,11.274-.694l5.5,2.92a4.933,4.933,0,0,0,4.571.031L42.9,40.34a3.537,3.537,0,0,1,4.671,1.378,1.011,1.011,0,0,1-.354,1.374l-11.128,6.6a8.817,8.817,0,0,1-6.472,1.025l-10.036-2.4-.464,1.946,10.045,2.4a10.807,10.807,0,0,0,7.947-1.251l11.128-6.6a3,3,0,0,0,1.455-2.653L56.3,38.778a2.735,2.735,0,0,1,3.519.929l.011.017A1.027,1.027,0,0,1,59.56,41.121Z"
            fill="#fb4a5b"
            data-original="#000000"
          />
          <rect
            x="6.7"
            y="41.565"
            width="2"
            height="1.998"
            transform="translate(-8.585 2.492) rotate(-11.862)"
            fill="#fb4a5b"
            data-original="#000000"
          />
          <path
            d="M31.411,39.808a1,1,0,0,0,1.178,0C42.623,32.489,48.8,25.51,51.044,19h5.14a3,3,0,1,0,0-2H51.6a16.237,16.237,0,0,0,.355-2.239,11.364,11.364,0,0,0-2.514-8.535A10.573,10.573,0,0,0,40.973,2,14.676,14.676,0,0,0,32,5.518,14.676,14.676,0,0,0,23.027,2a10.578,10.578,0,0,0-8.47,4.226,11.37,11.37,0,0,0-2.513,8.535C12.647,22.446,19.163,30.874,31.411,39.808ZM59,17a1,1,0,1,1-1,1A1,1,0,0,1,59,17ZM16.044,7.563a.963.963,0,0,0,.125-.152A8.6,8.6,0,0,1,23.027,4c4.634,0,8.23,3.529,8.266,3.564a1,1,0,0,0,1.415,0C32.744,7.527,36.314,4,40.973,4a8.535,8.535,0,0,1,6.858,3.411.963.963,0,0,0,.125.152A9.3,9.3,0,0,1,49.962,14.6a13.96,13.96,0,0,1-.41,2.4H41.58l-3.712-6.5a1,1,0,0,0-1.8.125L31.123,23,27.955,12.706a1,1,0,0,0-1.813-.221L23.433,17H17v2h7a1,1,0,0,0,.857-.485L26.7,15.438l3.341,10.856a1,1,0,0,0,.915.7H31a1,1,0,0,0,.929-.629l5.23-13.077,2.973,5.2A1,1,0,0,0,41,19h7.9C47.049,23.712,42.445,30.032,32,37.759,17.909,27.335,14.449,19.468,14.044,14.68A9.3,9.3,0,0,1,16.044,7.563Z"
            fill="#fb4a5b"
            data-original="#000000"
          />
          <rect
            x="2"
            y="17"
            width="8"
            height="2"
            fill="#fb4a5b"
            data-original="#000000"
          />
        </g>
      </g>
    </svg>
  );
};

export default HealthSvg;
