import React from "react";
import styles from "./messenger.module.css";

const Modal = () => {
  return (
    <div className={styles.content}>
      <div className={styles.top}>patt</div>
      Modal
    </div>
  );
};

export default Modal;
